import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProduitListComponent} from './produit-list.component';
import {ProduitService} from './produit.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AdLibraryModule} from 'ad-library';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CaissePipesModule} from '../../../common/pipes/caisse-pipes.module';
import {NgxBootstrapSharedModule} from '../../../shared/ngxbootstrap-shared.module';
import {ProduitFormComponent} from './produit-form.component';

@NgModule({
  declarations: [ProduitListComponent, ProduitFormComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdLibraryModule, DragDropModule, CaissePipesModule, NgxBootstrapSharedModule],
  exports: [ProduitListComponent, ProduitFormComponent],
  providers: [ProduitService],
})
export class ProduitModule {
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private subject = new Subject<any>();

  constructor() {}

  broadcastMessage(message: string) {
    this.subject.next(message);
  }

  // clearMessages() {
  //   this.subject.next();
  // }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}

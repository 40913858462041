import { Component, OnInit } from '@angular/core';
import {ProduitService} from './produit.service';
import {Produit} from '../../../common/model/produit';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdministrationService} from '../../../common/administration/administration.service';

@Component({
  selector: 'app-produit-page',
  templateUrl: './produit-list.component.html',
  styleUrls: ['./produit-list.component.scss']
})
export class ProduitListComponent implements OnInit {

  produits: Produit[] = [];

  loading = false;
  search = '';

  constructor(private produitsService: ProduitService,
              private administrationService: AdministrationService,
              private router: Router) { }

  ngOnInit() {
    this.findListe();
  }

  async findListe() {
    try {
      this.loading = true;

      // charger les produits
      this.produits = await this.produitsService.list().toPromise();
      console.log(this.produits);
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
    }
  }

  async askDelete($event, produit: Produit) {
    $event.stopPropagation();
    await this.produitsService.remove(produit.id).toPromise();
    this.findListe();
  }

  askEdit(produit: Produit) {
    return this.router.navigate(['/auth/produits', produit.id]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.produits, event.previousIndex, event.currentIndex);
    this.administrationService.reorder('produit', event.previousIndex, event.currentIndex).toPromise();
  }

  isDraggable() {
    return this.search.length === 0;
  }

}

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterUtilsService {

  constructor(private router: Router) {}

  is404Page(url: NavigationEnd): boolean {
    return this.hasPage(url, '404');
  }

  hasPage(url: NavigationEnd, page: string): boolean {
    const path = url.urlAfterRedirects;
    return path.toLocaleLowerCase().includes(page.toLocaleLowerCase());
  }

  toLoginPage(): Promise<boolean> {
    return this.router.navigate([ '/login' ]);
  }

  toLogoutPage(): Promise<boolean> {
    return this.router.navigate([ '/logout' ]);
  }

  toAuthPage(): Promise<boolean> {
    return this.router.navigate([ '/auth' ]);
  }

}

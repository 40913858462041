import { Component, OnInit } from '@angular/core';
import { Produit } from 'src/app/common/model/produit';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormFactory } from 'src/app/shared/form-factory';
import { ProduitService } from './produit.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-produit-form',
  templateUrl: './produit-form.component.html',
  styleUrls: ['./produit-form.component.scss']
})
export class ProduitFormComponent implements OnInit {

  produit: Produit = {} as Produit;  // BEAN
  formGroup: FormGroup;           // FORM (initialisé avec FormFactory)

  beanId: number = null;  // Si formulaire en mode édition, c'est l'id du bean concerné
  editionMode = true;     // Permet de savoir si formulaire en mode ajout ou edition
  loading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private produitService: ProduitService) {
      this.beanId = +this.route.snapshot.params.id;
      if ( !this.beanId ) {
        this.beanId = null;
        this.editionMode = false;
      }
    }


  ngOnInit() {
    this.formGroup = this.fb.group( FormFactory.createFormProduit() );
    if (this.editionMode) {
      this.refreshBean();
    }
  }

  switchToEditionMode() {
    this.editionMode = true;
    this.beanId = this.produit.id;
  }

  async refreshBean() {
    try {
      this.loading = true;
      this.produit = await this.produitService.get(this.beanId).toPromise();
      this.formGroup.patchValue( this.produit );
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  isFormValid() {
    return this.formGroup.valid;
  }

  askBack() {
    window.history.back();
  }

  async askSave() {
    try {
      this.loading = true;
      this.produit = await this.produitService.save( this.formGroup.value ).toPromise();
      this.askBack();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
    console.log('ask save', this.produit);
  }

}

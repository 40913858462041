import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LoginService } from './login.service';
import { RouterUtilsService } from 'src/app/common/router/router-utils.service';
import { LocalService } from 'src/app/common/security/local-service.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public formLogin: FormGroup;

  version = environment.version;

  constructor(
    public router: Router,
    public routerUtils: RouterUtilsService,
    private fb: FormBuilder,
    private loginService: LoginService
    ) {
    this.initForm();
  }

  ngOnInit() {
  }

  initForm() {
      const login = environment.production ? '' : 'alex.lauwers@gmail.com';
      const password = environment.production ? '' : 'pixelperfect';

      this.formLogin = this.fb.group({
          'email' : [login, Validators.compose([Validators.required /*, ValidatorsFactory.controlEmail */ ]) ],
          'password': [password, Validators.required]
      });
  }

  async askLogin() {
    const credential = this.formLogin.getRawValue();
    this.formLogin.setErrors({loading : true});

    try {
      const loginResponse = await this.loginService.login(credential.email, credential.password).toPromise();
      LocalService.storeToken(loginResponse.access_token);
      this.routerUtils.toAuthPage();
    } catch (ex) {

      if ( ex.status && ex.status === 401 ) {
        this.formLogin.setErrors({authFailedConnexion : true});
      } else {
        this.formLogin.setErrors({failedConnexion : true});
      }
      console.warn(ex);

    } finally {

    }

}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxBootstrapSharedModule } from '../../shared/ngxbootstrap-shared.module';
import { LayoutModule } from 'ad-angle-layout';
import { AuthPageComponent } from './auth-page.component';
import {ProduitModule} from './produits/produit.module';
import {CaisseModule} from './caisses/caisse.module';
import {CaisseProduitsModule} from './caisse-produits/caisse-produits.module';
import {EvenementModule} from './evenements/evenement.module';



@NgModule({
  declarations: [
    AuthPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgxBootstrapSharedModule,
    HttpClientModule,
    NgSelectModule,
    ProduitModule,
    CaisseModule,
    CaisseProduitsModule,
    EvenementModule
  ],
  exports: [
    AuthPageComponent,
  ]
})
export class AuthPagesModule { }

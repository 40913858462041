import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusSearch'
})
export class StatusSearchPipe implements PipeTransform {

  transform(objects: any[], status: string, col: string): any {

    if (!status || status === '') {
      return objects;
    }
    return objects.filter((object) => {
      if (object[col].match(status)) {
        return true;
      }
      return false;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {EvenementService} from './evenement.service';
import {Evenement} from '../../../common/model/evenement';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CaisseService} from '../caisses/caisse.service';
import {Caisse} from '../../../common/model/caisse';

@Component({
  selector: 'app-evenement-detail-page',
  templateUrl: './evenement-detail.component.html',
  styleUrls: ['./evenement-detail.component.scss']
})
export class EvenementDetailComponent implements OnInit {

  beanId: number = null;
  evenement: Evenement;
  caisses: Caisse[];
  type: string = null;

  loading = false;

  constructor(private evenementsService: EvenementService,
              private caissesService: CaisseService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router) {
    this.beanId = +this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.refreshBean();
  }

  async refreshBean() {
    try {
      this.loading = true;
      this.evenement = await this.evenementsService.get(this.beanId).toPromise();
      this.caisses = await this.caissesService.list().toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  setType(type: string) {
    this.type = type;
  }


}

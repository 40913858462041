import { Routes } from '@angular/router';
import { MENUS_ROUTES } from 'src/app/menus/menus.routes';
import {ProduitListComponent} from './produits/produit-list.component';
import {ProduitFormComponent} from './produits/produit-form.component';
import {CaisseListComponent} from './caisses/caisse-list.component';
import {CaisseFormComponent} from './caisses/caisse-form.component';
import {CaisseProduitListComponent} from './caisse-produits/caisse-produit-list.component';
import {EvenementFormComponent} from './evenements/evenement-form.component';
import {EvenementListComponent} from './evenements/evenement-list.component';
import {EvenementDetailComponent} from './evenements/evenement-detail.component';


export const AUTH_PAGES_ROUTES: Routes = [
  ...MENUS_ROUTES,
  { path: 'produits', component: ProduitListComponent },
  { path: 'produits/add', component: ProduitFormComponent },
  { path: 'produits/:id', component: ProduitFormComponent },
  { path: 'caisses', component: CaisseListComponent },
  { path: 'caisses/add', component: CaisseFormComponent },
  { path: 'caisses/:id', component: CaisseFormComponent },
  { path: 'caisses-produits', component: CaisseProduitListComponent },
  { path: 'evenements', component: EvenementListComponent },
  { path: 'evenements/add', component: EvenementFormComponent },
  { path: 'evenements/:id', component: EvenementFormComponent },
  { path: 'evenement-detail/:id', component: EvenementDetailComponent },
  { path: '**', redirectTo: 'produits', pathMatch: 'full' }
];

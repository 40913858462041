import { Component, OnInit } from '@angular/core';
import {EvenementService} from './evenement.service';
import {Evenement} from '../../../common/model/evenement';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdministrationService} from '../../../common/administration/administration.service';

@Component({
  selector: 'app-evenement-page',
  templateUrl: './evenement-list.component.html',
  styleUrls: ['./evenement-list.component.scss']
})
export class EvenementListComponent implements OnInit {

  evenements: Evenement[] = [];

  categorieFilter = -1;

  loading = false;
  search = '';

  constructor(private evenementsService: EvenementService,
              private administrationService: AdministrationService,
              private router: Router) { }

  ngOnInit() {
    this.findListe();
  }

  async findListe() {
    try {
      this.loading = true;

      // charger les evenements
      this.evenements = await this.evenementsService.list().toPromise();
      console.log(this.evenements);
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
    }
  }

  async askDelete($event, evenement: Evenement) {
    $event.stopPropagation();
    await this.evenementsService.remove(evenement.id).toPromise();
    this.findListe();
  }

  askView(evenement: Evenement) {
    return this.router.navigate(['/auth/evenement-detail', evenement.id]);
  }


  askEdit(evenement: Evenement) {
    return this.router.navigate(['/auth/evenements', evenement.id]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.evenements, event.previousIndex, event.currentIndex);
    this.administrationService.reorder('evenements', event.previousIndex, event.currentIndex).toPromise();
  }

  isDraggable() {
    return false;
  }

}

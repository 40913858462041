import { Validators, FormArray } from '@angular/forms';
import * as moment from 'moment';
import {ValidatorsFactory} from './validators-factory';

export class FormFactory {

  static createFormProduit(): any {
    return {
      id: [null],
      libelle: [null, Validators.required],
      ligne1: [null, Validators.required],
      ligne2: [null],
      prix: [null, [Validators.required, Validators.pattern(/^[.\d]+$/)]],
      active: [true]
    };
  }

  static createFormCaisse(isEdition: boolean): any {
    const form = {
      id: [null],
      nom: [null, Validators.required],
      login: [null, Validators.required],
      active: [true]
    };
    return FormFactory.addPasswordToForm(form, isEdition);
  }

  static createFormEvenement(): any {
    return {
      id: [null],
      nom: [null, Validators.required],
      dateDebut: [new Date(), Validators.required],
      dateFin: [new Date(), Validators.required],
      active: [true]
    };
  }

  static createFormRechercherEvenement(idEvenement, type: string): any {

    const currenttimestamp = new Date().getTime();
    const onedayaftertimestamp = currenttimestamp + 86400000;
    const ondayafterdate = new Date(onedayaftertimestamp);

    return {
      idEvenement: [idEvenement],
      dateDebut: [new Date(), Validators.required],
      dateFin: [ondayafterdate, Validators.required],
      type: [type]
    };
  }



  static addPasswordToForm(form: any, isEdition: boolean) {
    if (isEdition) {
      form.password = ['', [Validators.minLength(5), Validators.pattern(`[^' ']+`)]];
      form.passwordRetype = ['', [ValidatorsFactory.controlPasswordRetype('password')]];
    } else {
      form.password = ['', [Validators.required, Validators.minLength(5), Validators.pattern(`[^' ']+`)]];
      form.passwordRetype = ['', [Validators.required, ValidatorsFactory.controlPasswordRetype('password')]];
    }
    return form;
  }



}

import { Routes } from '@angular/router';
import { MenuTopLogoComponent } from './menu-top-logo.component';
import { MenuTopLeftComponent } from './menu-top-left.component';
import { MenuTopRightComponent } from './menu-top-right.component';
import { MenuRightComponent } from './menu-right.component';
import { MenuBottomComponent } from './menu-bottom.component';

export const MENUS_ROUTES: Routes = [
  {path: '', component: MenuTopLogoComponent, outlet: 'menu-top-logo'},
  {path: '', component: MenuTopLeftComponent, outlet: 'menu-top-left'},
  {path: '', component: MenuTopRightComponent, outlet: 'menu-top-right'},
  {path: '', component: MenuRightComponent, outlet: 'menu-right'},
  {path: '', component: MenuBottomComponent, outlet: 'menu-bottom'},
];

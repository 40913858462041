import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from 'ad-angle-layout';

@Component({
  selector: 'app-menu-right',
  templateUrl: './menu-right.component.html',
  styleUrls: ['./menu-right.component.scss']
})
export class MenuRightComponent implements OnInit {

  constructor(public settings: SettingsService) { }

  ngOnInit() {
  }

}

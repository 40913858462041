import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EvenementListComponent} from './evenement-list.component';
import {EvenementService} from './evenement.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AdLibraryModule} from 'ad-library';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CaissePipesModule} from '../../../common/pipes/caisse-pipes.module';
import {NgxBootstrapSharedModule} from '../../../shared/ngxbootstrap-shared.module';
import {EvenementFormComponent} from './evenement-form.component';
import {EvenementDetailComponent} from './evenement-detail.component';
import {EvenementDetailTransactionsComponent} from './evenement-detail-transactions/evenement-detail-transactions.component';
import {EvenementDetailProduitsTotauxComponent} from './evenement-detail-produits-totaux/evenement-detail-produits-totaux.component';
import {EvenementCAComponent} from './evenement-ca/evenement-ca.component';

@NgModule({
  declarations: [EvenementListComponent,
    EvenementFormComponent,
    EvenementDetailComponent,
    EvenementDetailTransactionsComponent,
    EvenementDetailProduitsTotauxComponent,
    EvenementCAComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule, AdLibraryModule,
    DragDropModule, CaissePipesModule,
    NgxBootstrapSharedModule],
  exports: [EvenementListComponent,
    EvenementFormComponent,
    EvenementDetailComponent,
    EvenementDetailTransactionsComponent,
    EvenementDetailProduitsTotauxComponent,
    EvenementCAComponent],
  providers: [EvenementService],
})
export class EvenementModule {
}

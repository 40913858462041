import { Component, OnInit } from '@angular/core';
import { RouterUtilsService } from 'src/app/common/router/router-utils.service';
import { LocalService } from 'src/app/common/security/local-service.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(public routerUtils: RouterUtilsService) { }

  ngOnInit() {
    console.log('okk');
    LocalService.logout();
    this.routerUtils.toLoginPage();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CaisseProduitsService} from './caisse-produits.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AdLibraryModule} from 'ad-library';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CaissePipesModule} from '../../../common/pipes/caisse-pipes.module';
import {NgxBootstrapSharedModule} from '../../../shared/ngxbootstrap-shared.module';
import {CaisseProduitListComponent} from './caisse-produit-list.component';

@NgModule({
  declarations: [CaisseProduitListComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, AdLibraryModule, DragDropModule, CaissePipesModule, NgxBootstrapSharedModule],
  exports: [CaisseProduitListComponent],
  providers: [CaisseProduitsService],
})
export class CaisseProduitsModule {
}

import { Component, OnInit } from '@angular/core';
import {CaisseService} from './caisse.service';
import {Caisse} from '../../../common/model/caisse';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdministrationService} from '../../../common/administration/administration.service';

@Component({
  selector: 'app-caisse-page',
  templateUrl: './caisse-list.component.html',
  styleUrls: ['./caisse-list.component.scss']
})
export class CaisseListComponent implements OnInit {

  caisses: Caisse[] = [];

  categorieFilter = -1;

  loading = false;
  search = '';

  constructor(private caissesService: CaisseService,
              private administrationService: AdministrationService,
              private router: Router) { }

  ngOnInit() {
    this.findListe();
  }

  async findListe() {
    try {
      this.loading = true;

      // charger les caisses
      this.caisses = await this.caissesService.list().toPromise();
      console.log(this.caisses);
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loading = false;
    }
  }

  async askDelete($event, caisse: Caisse) {
    $event.stopPropagation();
    await this.caissesService.remove(caisse.id).toPromise();
    this.findListe();
  }

  askEdit(caisse: Caisse) {
    return this.router.navigate(['/auth/caisses', caisse.id]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.caisses, event.previousIndex, event.currentIndex);
    this.administrationService.reorder('caisses', event.previousIndex, event.currentIndex).toPromise();
  }

  isDraggable() {
    return false;
  }

}

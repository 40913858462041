import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

declare var localStorage: any;

@Injectable({
  providedIn: 'root'
})
export class LocalService {

    public static TOKEN = 'token';

    public static storeToken(token: string) {
        localStorage.setItem(LocalService.TOKEN, token);
    }

    public static getToken(): string {
        if (!LocalService.isAuthenticated()) {
            return null;
        }
        return localStorage.getItem(LocalService.TOKEN);
    }

    public static isAuthenticated() {
        return localStorage.getItem(LocalService.TOKEN) && localStorage.getItem(LocalService.TOKEN) != null ? true : false;
    }

    public static isTokenExpired() {
        const jwtHelper = new JwtHelperService();
        if (!this.isAuthenticated()) {
            return true;
        }
        return jwtHelper.isTokenExpired(this.getToken());
    }

    public static logout() {
        localStorage.clear();
    }

}

import { Component, OnInit } from '@angular/core';
import { Evenement } from 'src/app/common/model/evenement';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormFactory } from 'src/app/shared/form-factory';
import { EvenementService } from './evenement.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-evenement-form',
  templateUrl: './evenement-form.component.html',
  styleUrls: ['./evenement-form.component.scss']
})
export class EvenementFormComponent implements OnInit {

  evenement: Evenement = {} as Evenement;  // BEAN
  formGroup: FormGroup;           // FORM (initialisé avec FormFactory)

  beanId: number = null;  // Si formulaire en mode édition, c'est l'id du bean concerné
  editionMode = true;     // Permet de savoir si formulaire en mode ajout ou edition
  loading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private evenementService: EvenementService) {
      this.beanId = +this.route.snapshot.params.id;
      if ( !this.beanId ) {
        this.beanId = null;
        this.editionMode = false;
      }
    }


  ngOnInit() {
    this.formGroup = this.fb.group( FormFactory.createFormEvenement() );
    if (this.editionMode) {
      this.refreshBean();
    }
  }

  switchToEditionMode() {
    this.editionMode = true;
    this.beanId = this.evenement.id;
  }

  async refreshBean() {
    try {
      this.loading = true;
      this.evenement = await this.evenementService.get(this.beanId).toPromise();
      this.formGroup.patchValue( this.evenement );
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  isFormValid() {
    return this.formGroup.valid;
  }

  askBack() {
    window.history.back();
  }

  async askSave() {
    try {
      this.loading = true;
      this.evenement = await this.evenementService.save( this.formGroup.value ).toPromise();
      this.formGroup.patchValue( this.evenement );
      this.askBack();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
    console.log('ask save', this.evenement);
  }

}

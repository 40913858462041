import { map } from 'rxjs/operators';

export class DateUtils {

  static ObjectStringToDates(bean: any, fields: string[]) {
    for (const field of fields) {
      // TRES IMPORTANT ! : la transformation doit comprendre le Z pour activer le timezone UTC
      // sinon problème avec notre fuseau horaire et décallage de 1 ou 2heures
      // Si on ne fait pas ça, on aura un bug avec le datepicker
      if (bean[field]) {
        bean[field] = new Date(bean[field] + 'T00:00:00Z');
      }
    }
    return bean;
  }

  static ArrayStringToDates(beans: any[], fields: string[]) {
    beans.map( bean => {
      return DateUtils.ObjectStringToDates(bean, fields);
    });
    return beans;
  }

}

export function convertDates(fields: string[]) {
  return map( (bean: any) => {
    if ( !bean.length )  {
      return DateUtils.ObjectStringToDates(bean, fields);
    } else {
      return DateUtils.ArrayStringToDates(bean, fields);
    }

  });
}

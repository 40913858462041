import {NgModule} from '@angular/core';
import {StringSearchPipe} from './string-search.pipe';
import {StatusSearchPipe} from './status-search.pipe';
import {ReferenceSearchPipe} from './reference-search.pipe';

@NgModule({
  declarations: [StringSearchPipe, StatusSearchPipe, ReferenceSearchPipe],
  imports: [],
  exports: [StringSearchPipe, StatusSearchPipe, ReferenceSearchPipe],
  providers: [],
})
export class CaissePipesModule {
}

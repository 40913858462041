import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Produit} from '../../../common/model/produit';

@Injectable()
export class ProduitService {

    private url = environment.url + 'produits';

    constructor(private http: HttpClient) {}

    list(): Observable<Produit[]> {
        return this.http.get(this.url) as Observable<Produit[]>;
    }

    get(id: number): Observable<Produit> {
        return this.http.get(this.url + '/' + id) as Observable<Produit>;
    }

    save(produit: Produit): Observable<Produit> {
        if (produit.id && produit.id >= 0) {
            return this.http.put(this.url, produit) as Observable<Produit>;
        } else {
            return this.http.post(this.url, produit) as Observable<Produit>;
        }
    }

    remove(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgxBootstrapSharedModule } from '../shared/ngxbootstrap-shared.module';

import { MenuTopLeftComponent } from './menu-top-left.component';
import { MenuTopRightComponent } from './menu-top-right.component';
import { MenuTopLogoComponent } from './menu-top-logo.component';
import { MenuRightComponent } from './menu-right.component';
import { MenuBottomComponent } from './menu-bottom.component';

@NgModule({
  declarations: [
    MenuTopLeftComponent,
    MenuTopRightComponent,
    MenuTopLogoComponent,
    MenuRightComponent,
    MenuBottomComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxBootstrapSharedModule,
  ],
  exports: [
    MenuTopLeftComponent,
    MenuTopRightComponent,
    MenuTopLogoComponent,
    MenuRightComponent,
    MenuBottomComponent]
})
export class MenusModule { }

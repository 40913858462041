import { Component, OnInit } from '@angular/core';
import { SettingsService, MenuService } from 'ad-angle-layout';

@Component({
  selector: 'app-menu-top-left',
  templateUrl: './menu-top-left.component.html',
  styleUrls: ['./menu-top-left.component.scss']
})
export class MenuTopLeftComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout

  constructor(
    public menu: MenuService,
    public settings: SettingsService) {

      // show only a few items on demo
      this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
  }

  ngOnInit() {
  }

  toggleUserBlock(event) {
      event.preventDefault();
      // this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
      event.preventDefault();
      event.stopPropagation();
      this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
      // console.log(stat);
      // this.isNavSearchVisible = stat;
  }

  toggleOffsidebar() {
      this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSidebar() {
      this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
      return this.settings.getLayoutSetting('isCollapsedText');
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-page',
  template: '<ad-layout></ad-layout>'
})
export class AuthPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringSearch'
})
export class StringSearchPipe implements PipeTransform {

  transform(objects: any[], search: string, cols: string[]): any {
    if (!search) {
      return objects;
    }
    const s = search.toLowerCase();
    return objects.filter((object) => {
      for (const col of cols) {
        if (object[col].toLowerCase().match(s)) {
          return true;
        }
      }
      return false;
    });
  }

}

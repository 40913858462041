import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  private url = environment.url + 'administration';

  constructor(private http: HttpClient) {}

  reorder(concept: string, oldIndex: number, newIndex: number): Observable<any> {
    const form = new FormData();
    form.append('concept', concept);
    form.append('oldIndex', '' + oldIndex);
    form.append('newIndex', '' + newIndex);
    console.log('reorder');
    console.log(form);
    return this.http.post(this.url + '/reorder', form) as Observable<any>;
  }

  count(concept: string, status: string): Observable<any> {
    const form = new FormData();
    form.append('concept', concept);
    form.append('status', '' + status);
    return this.http.post(this.url + '/count', form) as Observable<any>;
  }


}

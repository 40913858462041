import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Evenement} from '../../../../common/model/evenement';
import {EvenementService} from '../evenement.service';
import {FormFactory} from '../../../../shared/form-factory';
import {CaisseService} from '../../caisses/caisse.service';
import {Caisse} from '../../../../common/model/caisse';
import {Transaction} from '../../../../common/model/transaction';

@Component({
  selector: 'app-evenement-detail-transactions-page',
  templateUrl: './evenement-detail-transactions.component.html'
})
export class EvenementDetailTransactionsComponent implements OnInit {

  @Input() evenement: Evenement;
  @Input() caisses: Caisse[];

  transactions: Transaction[] = [];
  currentTransaction: Transaction = null;

  loading = false;
  selectedCaisse: Caisse = null;

  constructor(private evenementsService: EvenementService,
              private caissesService: CaisseService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    console.log(this.evenement);
  }

  async setCaisse(caisse: Caisse) {
    this.loading = true;
    this.selectedCaisse = caisse;
    this.transactions = await this.evenementsService.getTransactions(this.evenement.id, caisse.id).toPromise();
    console.log(this.transactions);
    this.loading = false;
  }

  async setCurrentTransaction(transaction: Transaction) {
    this.loading = true;
    this.currentTransaction = await this.evenementsService.getTransaction(transaction.id).toPromise();
    console.log(this.currentTransaction);
    this.loading = false;
  }


}

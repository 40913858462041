import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'ad-angle-layout';
import {AdministrationService} from '../common/administration/administration.service';
import {BroadcastService} from '../common/administration/broadcast.service';


@Component({
  selector: 'app-menu-top-right',
  templateUrl: './menu-top-right.component.html',
  styleUrls: ['./menu-top-right.component.scss']
})
export class MenuTopRightComponent implements OnInit {

  constructor(public settings: SettingsService,
              public administrationService: AdministrationService,
              public broadcastService: BroadcastService) { }

  countAnnonces = 0;

  ngOnInit() {
    // this.initAlerts();
    this.subscribeToMessages();
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  initAlerts() {
    this.initAnnonceAlerts();
  }

  async initAnnonceAlerts() {
    // this.countAnnonces = await this.administrationService.count('annonce', 'AVALIDER').toPromise();
  }

  subscribeToMessages() {
    this.broadcastService.getMessage().subscribe(message => {
      console.log('MESSAGE');
      console.log(message);
      if (message && 'annonce' === message) {
        this.initAnnonceAlerts();
      }
    });
  }


}

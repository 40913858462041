import {Component, OnInit} from '@angular/core';
import { MenuService } from 'ad-angle-layout';
import { MENU_LINKS } from './menus/menus.links';
import {AdministrationService} from './common/administration/administration.service';
import {BroadcastService} from './common/administration/broadcast.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public menu: MenuService,
              public administrationService: AdministrationService,
              public broadcastService: BroadcastService,
              private localeService: BsLocaleService) {
    this.localeService.use('fr');
    this.menu.addMenu(MENU_LINKS);
    this.setMenuAlerts();
  }

  setMenuAlerts() {
    this.applyAnnonceAlert();
    this.subscribeToMessages();
  }

  async applyAnnonceAlert() {
    // const countAnnonces = await this.administrationService.count('annonce', 'AVALIDER').toPromise();
    // this.applyAlert('Annonces', countAnnonces);
  }


  applyAlert(concept: string, count: number) {
    this.menu.menuItems.forEach(menuPoint => {
      if (menuPoint.text === concept) {
        menuPoint.alert = count > 0 ? '' + count : null;
        menuPoint.label = 'badge badge-primary';
      }
    });
  }

  subscribeToMessages() {
    this.broadcastService.getMessage().subscribe(message => {
      console.log('MESSAGE');
      console.log(message);
      if (message && 'annonce' === message) {
        this.applyAnnonceAlert();
      }
    });
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Evenement} from '../../../common/model/evenement';
import {convertDates} from '../../../shared/date-utils';
import {Transaction} from '../../../common/model/transaction';
import {Vendu} from "../../../common/model/vendu";

@Injectable()
export class EvenementService {

    private url = environment.url + 'evenements';
    private urlTransactions = environment.url + 'transactions';

    constructor(private http: HttpClient) {}

    list(): Observable<Evenement[]> {
        return this.http.get(this.url).pipe( convertDates(['dateDebut', 'dateFin'] ));
    }

    get(id: number): Observable<Evenement> {
        return this.http.get(this.url + '/' + id).pipe( convertDates(['dateDebut', 'dateFin'] ));
    }

    save(evenement: Evenement): Observable<Evenement> {
        if (evenement.id && evenement.id >= 0) {
            return this.http.put(this.url, evenement).pipe( convertDates(['dateDebut', 'dateFin'] ));
        } else {
            return this.http.post(this.url, evenement).pipe( convertDates(['dateDebut', 'dateFin'] ));
        }
    }

    remove(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

    getTransactions($idEvenement, $idCaisse): Observable<Transaction[]> {
      return this.http.get(this.urlTransactions + '/' + $idEvenement + '/' + $idCaisse) as Observable<Transaction[]>;
    }

    getProduitsVendus($values): Observable<Vendu[]> {
      console.log($values);
      return this.http.post(this.urlTransactions + '-produits', $values ) as Observable<Vendu[]>;
    }

    getTransaction($idTransaction): Observable<Transaction> {
      return this.http.get(this.urlTransactions + '-detail/' + $idTransaction) as Observable<Transaction>;
    }

}

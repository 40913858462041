import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from 'src/app/common/model/login-response';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public http: HttpClient) {

  }

  login(email: string, password: string): Observable<LoginResponse> {
    const form = new FormData();
    form.append('email', email);
    form.append('password', password);
    return <Observable<LoginResponse>> this.http.post('auth/login', form);
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Caisse} from '../../../common/model/caisse';

@Injectable()
export class CaisseService {

    private url = environment.url + 'caisses';

    constructor(private http: HttpClient) {}

    list(): Observable<Caisse[]> {
        return this.http.get(this.url) as Observable<Caisse[]>;
    }

    get(id: number): Observable<Caisse> {
        return this.http.get(this.url + '/' + id) as Observable<Caisse>;
    }

    save(caisse: Caisse): Observable<Caisse> {
        if (caisse.id && caisse.id >= 0) {
            return this.http.put(this.url, caisse) as Observable<Caisse>;
        } else {
            return this.http.post(this.url, caisse) as Observable<Caisse>;
        }
    }

    remove(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

}

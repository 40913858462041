import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'referenceSearch'
})
export class ReferenceSearchPipe implements PipeTransform {

  transform(objects: any[], id: number, cols: string[]): any {

    // console.log(objects);
    // console.log(id);
    // console.log(cols);

    if (!id || id < 0) {
      return objects;
    }
    return objects.filter((object) => {
      for (const col of cols) {
        if (object[col] === id) {
          return true;
        }
      }
      return false;
    });
  }

}

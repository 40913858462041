import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/public/login-page/login-page.component';
import { PUBLIC_PAGES_ROUTES } from './pages/public/public-pages.routes';
import { AUTH_PAGES_ROUTES } from './pages/auth/auth-pages.routes';
import { AuthPageComponent } from './pages/auth/auth-page.component';
import { AuthGuard } from './common/security/auth-guard.service';
import { LogoutComponent } from './pages/logout/logout.component';

export const APP_ROUTES: Routes = [
  { path: 'public', component: LoginPageComponent, children: [
    ...PUBLIC_PAGES_ROUTES
  ]}, {
    path: 'auth', component: AuthPageComponent, canActivate: [ AuthGuard ], children: [
      ...AUTH_PAGES_ROUTES
    ]
  },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: 'public', pathMatch: 'full' }
];

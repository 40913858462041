
import {Observable} from 'rxjs';
import {AbstractControl} from '@angular/forms';

export class ValidatorsFactory {

    static EMAIL_PATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    static controlEmail(control) {
        const EMAIL_REGEXP = ValidatorsFactory.EMAIL_PATTERN;
        if (control.value === '') { return; }
        if (!EMAIL_REGEXP.test(control.value)) {
            return {invalidEmail: true};
        }
    }

    static controlInvalidSelect(valueToHave: any) {
        return (control) => {
          const value = control.value;
          if (!value) {
              return {invalidSelectEqual: true};
          }
          if (value && value.id === valueToHave.id) {
              return {invalidSelectEqual: true};
          }
        };
    }

    static controlValueEqual(valueToHave: any) {
        return (control) => {
            const value = control.value;
            if (value !== valueToHave) {
                return {invalidValueEqual: true};
            }
        };
    }

    static controlPassword(control) {
      const password: string = control.value;
      if (password.trim().length <= 3) {
        return {invalidPassword: true};
      }
    }

    static controlPasswordRetype(passwordFieldName: string) {
        return (control) => {
            const password = control.root.value[passwordFieldName];
            const passwordRetype = control.value;
            if (password !== passwordRetype) {
                return {invalidPasswordRetype: true};
            }
        };
    }

    static toAsync( func: (c: AbstractControl) => any ): (c: AbstractControl) => any {
        return (control: AbstractControl) => {
            return new Observable( obs => {
                obs.next( func( control ) );
                obs.complete();
            });
        };
    }

}

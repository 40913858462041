import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Evenement} from '../../../../common/model/evenement';
import {EvenementService} from '../evenement.service';
import {FormFactory} from '../../../../shared/form-factory';
import {CaisseService} from '../../caisses/caisse.service';
import {Caisse} from '../../../../common/model/caisse';
import {Transaction} from '../../../../common/model/transaction';
import {Vendu} from '../../../../common/model/vendu';
import {ProduitService} from '../../produits/produit.service';
import {Produit} from '../../../../common/model/produit';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-evenement-detail-produits-totaux',
  templateUrl: './evenement-detail-produits-totaux.component.html'
})
export class EvenementDetailProduitsTotauxComponent implements OnInit {

  @Input() evenement: Evenement;
  @Input() caisses: Caisse[];
  @Input() type = 'total'; // total - vendu - bop

  produits: Produit[];
  vendus: Vendu[];

  loading = false;
  formGroup: FormGroup;           // debut - fin

  constructor(private evenementsService: EvenementService,
              private caissesService: CaisseService,
              private produitsService: ProduitService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group( FormFactory.createFormRechercherEvenement(this.evenement.id, this.type) );
    console.log(this.evenement);
    this.getProduitsEtVendus();
  }

  async getProduitsEtVendus() {
    this.loading = true;
    this.produits = await this.produitsService.list().toPromise();
    this.vendus = await this.evenementsService.getProduitsVendus(this.formGroup.value).toPromise();
    this.loading = false;
  }

  async askSearch() {
    this.loading = true;
    console.log(this.formGroup.value);
    this.vendus = await this.evenementsService.getProduitsVendus(this.formGroup.value).toPromise();
    this.repartirVendus();
    this.loading = false;
  }

  repartirVendus() {
    this.produits.forEach(produit => {
      const filtered = this.vendus.filter(x => x.produitId === produit.id);
      produit.ventes = filtered;
    });
    console.log(this.produits);
  }

  isFormValid() {
    return this.formGroup.valid;
  }

  getCaisseLibelle(idCaisse: number) {
    const caisse = this.caisses.find(x => x.id === idCaisse);
    return caisse.nom;
  }


}

import {Injectable} from '@angular/core';
import {RequestOptions, RequestOptionsArgs, Response, Headers} from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { LocalService } from './local-service.service';
import { RouterUtilsService } from '../router/router-utils.service';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {

    constructor(public routerUtils: RouterUtilsService, public localService: LocalService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = this.updateUrl(req.url);

        if (LocalService.isAuthenticated() && this.isFajaUrl(url)) {
            req = req.clone({
                url: url,
                setHeaders: {
                    authorization: 'Bearer ' + LocalService.getToken()
                }
            });
        } else {
            req = req.clone({
                url: url
            });
        }

        return next.handle(req);
    }

    handleAuth(response: Response) {
        // !response.url.toLocaleLowerCase().includes('/auth/login')
        if (response.status === 401 || response.status === 403) {
            LocalService.logout();
            this.routerUtils.toLogoutPage();
        }
        return response;
    }

    /* Parfois on sort vers OneDrive et dans ce cas il ne faut pas injecter le token faja */
    private isFajaUrl(url) {
      return url.includes('http://localhost') || url.includes('https://server.faja');
    }

    private updateUrl(req: string) {
        if (req.toLowerCase().startsWith('https://') || req.toLowerCase().startsWith('http://')) {
            return req;
        }
        return environment.url + req;
    }

    private getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
        if (options == null) {
            options = new RequestOptions();
        }
        if (options.headers == null) {

            if (LocalService.isAuthenticated()) {
                options.headers = new Headers({'Authorization': 'Bearer ' + LocalService.getToken()});
            } else {
                options.headers = new Headers();
            }

        }
        options.headers.append('Content-Type', 'application/json');

        return options;
    }
}

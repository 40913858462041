import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CaisseProduit} from '../../../common/model/caisse-produit';

@Injectable()
export class CaisseProduitsService {

    private url = environment.url + 'caisse-produits';

    constructor(private http: HttpClient) {}

    list(): Observable<CaisseProduit[]> {
        return this.http.get(this.url) as Observable<CaisseProduit[]>;
    }

    save(caisseProduit: CaisseProduit): Observable<CaisseProduit> {
        return this.http.post(this.url, caisseProduit) as Observable<CaisseProduit>;
    }

    remove(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id);
    }

}

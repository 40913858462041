import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { RouterUtilsService } from '../router/router-utils.service';
import { LocalService } from './local-service.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private routerUtils: RouterUtilsService) {
    }

    canActivate() {
      if (!LocalService.isTokenExpired()) {
        const jwtHelper = new JwtHelperService();
        const token = jwtHelper.decodeToken( LocalService.getToken() );
        if (token.isAdmin === 1) {
          return true;
        }
      }

      // not logged in so redirect to login page
      this.routerUtils.toLogoutPage();
      return false;
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxBootstrapSharedModule } from '../../shared/ngxbootstrap-shared.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { PublicPageComponent } from './public-page.component';


@NgModule({
  declarations: [
    LoginPageComponent,
    PublicPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapSharedModule,
    HttpClientModule,
  ],
  exports: [
    LoginPageComponent, PublicPageComponent
  ]
})
export class PublicPagesModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutModule } from 'ad-angle-layout';

import { AppComponent } from './app.component';
import { MenusModule } from './menus/menus.module';
import { environment } from '../environments/environment';
import { AuthPagesModule } from './pages/auth/auth-pages.module';
import { PublicPagesModule } from './pages/public/public-pages.module';
import { InterceptedHttp } from './common/security/http-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutComponent } from './pages/logout/logout.component';
import { APP_ROUTES } from './app.routes';
import {BsDropdownModule} from 'ngx-bootstrap';

declare var require: any;
environment.version = require('../../package.json').version;

@NgModule({
  declarations: [
    AppComponent, LogoutComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot( APP_ROUTES ),
    AuthPagesModule,
    PublicPagesModule,
    LayoutModule,
    MenusModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptedHttp, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

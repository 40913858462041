import { Component, OnInit } from '@angular/core';
import { Produit } from 'src/app/common/model/produit';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormFactory } from 'src/app/shared/form-factory';
import { CaisseProduitsService } from './caisse-produits.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CaisseProduit} from '../../../common/model/caisse-produit';
import {ProduitService} from '../produits/produit.service';
import {CaisseService} from '../caisses/caisse.service';
import {Caisse} from '../../../common/model/caisse';

@Component({
  selector: 'app-produit-form',
  templateUrl: './caisse-produit-list.component.html',
  styleUrls: ['./caisse-produit-list.component.scss']
})
export class CaisseProduitListComponent implements OnInit {

  loading = false;
  search = '';

  produits: Produit[] = [];
  caisses: Caisse[] = [];
  caisseProduits: CaisseProduit[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private produitsService: ProduitService,
    private caisseService: CaisseService,
    private caisseProduitsService: CaisseProduitsService) {
  }


  ngOnInit() {
    this.refreshBeans();
  }

  async refreshBeans() {
    try {
      this.loading = true;
      this.produits = await this.produitsService.list().toPromise();
      this.caisses = await this.caisseService.list().toPromise();
      this.caisseProduits = await this.caisseProduitsService.list().toPromise();

      this.produits.forEach(produit => {
        produit.associations = [];
        this.caisses.forEach(caisse => {
          const association = this.getAssociation(produit, caisse);
          produit.associations.push({
            produit,
            caisse,
            associated: association !== null,
            association
          });
        });
      });
      console.log(this.produits);

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  isDraggable() {
    return false;
  }

  getAssociation(produit: Produit, caisse: Caisse) {
    let association = null;
    this.caisseProduits.forEach(cp => {
        if (cp.idCaisse === caisse.id && cp.idProduit === produit.id) {
          association = cp;
        }
      }
    );
    return association;
  }

  async inverserAssociation(association: any) {
    console.log(association);
    if (association.associated) {
      // créer
      const cp = {} as CaisseProduit;
      cp.idProduit = association.produit.id;
      cp.idCaisse = association.caisse.id;
      association.association = await this.caisseProduitsService.save(cp).toPromise();
    } else {
      // supprimer
      await this.caisseProduitsService.remove(association.association.id).toPromise();
      association.association = null;
    }
  }


  askBack() {
    window.history.back();
  }
  //
  // async askSave() {
  //   try {
  //     this.loading = true;
  //     this.produit = await this.produitService.save( this.formGroup.value ).toPromise();
  //     this.formGroup.patchValue( this.produit );
  //     if (this.editionMode) {
  //       this.askBack();
  //     } else {
  //       this.switchToEditionMode();
  //     }
  //
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     this.loading = false;
  //   }
  //   console.log('ask save', this.produit);
  // }

}

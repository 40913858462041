import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-top-logo',
  templateUrl: './menu-top-logo.component.html',
  styleUrls: ['./menu-top-logo.component.scss']
})
export class MenuTopLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
